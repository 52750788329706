import {
  imageAdapter,
  imageUrlAdapter,
} from '@/adapters/constructor/utils/image';
import { linkAdapter } from '@/adapters/constructor/utils/link';

export default ({ data, type }) => {
  return {
    title: data.title,
    link: linkAdapter(data.link),
    image: imageAdapter(data.banner, data.banner_mobile),
    bottomImages: data.slider?.items.map(imageUrlAdapter),
    reverseColumns: type === 'IndexMir',
  };
};
